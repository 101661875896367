import { template as template_a677213ccc51480883ba83b8268e5fee } from "@ember/template-compiler";
const FKText = template_a677213ccc51480883ba83b8268e5fee(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
