import { template as template_c9f298d5c640486a84db0a85268728ab } from "@ember/template-compiler";
const FKLabel = template_c9f298d5c640486a84db0a85268728ab(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
