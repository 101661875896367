import { template as template_0110af1a21dc4d319e3cee31d3869476 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_0110af1a21dc4d319e3cee31d3869476(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
